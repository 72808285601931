import screen from "./screen";
import { connect } from "react-redux";
const {
  checkLogin,
  setAuthorization,
  signOut,
  getSensorData,
  stopLoader,
  startLoader
} = require(`../../redux/actions`);

const mapStateToProps = state => {
  return {
    userToken: state.CommonReducer.userToken,
    userData: state.CommonReducer.userData
  };
};
const mapDispatchToProps = dispatch => {
  return {
    checkLogin: (credentials, success) =>
      dispatch(checkLogin(credentials, success)),
    setAuthorization: userToken => dispatch(setAuthorization(userToken)),
    signOut: () => dispatch(signOut()),
    getSensorData:(data)=>dispatch(getSensorData(data)),
    stopLoader:()=>dispatch(stopLoader()),
    startLoader:()=>dispatch(startLoader())
  };
};

const Facility = connect(mapStateToProps, mapDispatchToProps)(screen);

export default Facility;
