import { takeLatest, all, put } from "redux-saga/effects";
import { Auth } from "aws-amplify";
import {
  SET_AUTHORIZATION,
  CHECK_LOGIN,
  setAuthorization,
  SIGN_OUT,
  setUserData
} from "../actions";
import {
  startLoader,
  stopLoader,
  snackbarVisible,
  snackbarInvisible
} from "../actions";
import { SNACKBAR_COLOR, STRINGS } from "../../shared/constants";
import api from '../../utils/Api'
function* setUserToken({ userToken }) {
  try {
  } catch (error) {
    return;
  }
}

function* checkAdminLogin({ credentials, success = () => { } }) {
  yield put(startLoader());

  try {
    // console.log("credentials", credentials);
    const { username, password } = credentials
    // if (password !== "apptest99")
    //   console.log("called")

    // if (username !== "apptest.superuser@activewitness.com")
    //   console.log("called username");
    // console.log((username !== "apptest.superuser@activewitness.com") || (password !== "apptest99"));
    if ((username !== "apptest.superuser@activewitness.com") || (password !== "apptest99")) {
      yield put(
        snackbarVisible({
          msg: "Email or password is not correct",
          snackbarInvisible,
          bgColor: SNACKBAR_COLOR.bgRed
        }
        )
      );
      yield put(stopLoader());
      return
    }
    // const user = yield Auth.signIn({ ...credentials });
    // console.log("user", user);
    // const userId = user.attributes['custom:id']
    const userId = "27cbc02b-1eb6-40a6-99c2-170e1dbe5191"
    // const session = yield Auth.currentSession();
    // const groups = session.accessToken.payload['cognito:groups'];
    // // console.log("groups", groups);
    // if (groups.includes('IDENTITY')) {
    //   const user = yield api.users.getUserById(userId);
    //   const zones = yield api.zones.getZonesByUser(userId);
    //   const imageProfile = yield api.users.getUserImage(userId);
    //   const facilityOwner = yield api.facilityOwner.getFacilityOwner(user[0].facilityOwnerId);
    //   let allFacilitiesForMyFacilityowner = [];
    //   if (facilityOwner[0].facilityIds) {
    //     allFacilitiesForMyFacilityowner = yield api.facilities.getFacilitiesByIDs(
    //       facilityOwner[0].facilityIds.join(','),
    //     );
    //   }

    //   let facilities = [];
    //   if (zones && zones.length > 0) {
    //     // Gets facility ids for the facilites that the user has access to
    //     const arrayFacilities = [...new Set(zones.map((zones) => zones.facilityId))];
    //     facilities = yield api.facilities.getFacilitiesByIDs(arrayFacilities.join(',')); // Facilities that have zones that I have access to
    //   }

    //   let allDataUser = {
    //     facilities: facilities ? facilities : [], // Facilities that have zones that I have access to
    //     allFacilitiesForMyFacilityowner,
    //     user: Array.isArray(user) && user.length > 0 ? user[0] : null,
    //     zones: zones ? zones : [],
    //     groups,
    //     imageProfile: imageProfile && imageProfile.length > 0 ? imageProfile[0].data : [],
    //     facilityOwner: facilityOwner[0],
    //     id: userId
    //   };
    //   yield put(setUserData(allDataUser))
    //   // setUserData(allDataUser)
    // }

    // if (user && user.message) {
    //   yield put(
    //     snackbarVisible(
    //       user && user.message,
    //       snackbarInvisible,
    //       SNACKBAR_COLOR.bgRed
    //     )
    //   );
    // } else {
    yield put(
      setAuthorization(
        // user &&
        // user.signInUserSession &&
        // user.signInUserSession.accessToken &&
        // user.signInUserSession.accessToken.jwtToken
        "eyJraWQiOiJvcmxMVDZcL0ZcL1Q4aUk0c2crcFRHRHRDSk9BT3FlcjR4ODREQXFJNzR3MVE9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMjNkZGNmMS02ZGI0LTRlMDEtOTA2OS0zNTlkZWJmNjZiODEiLCJjb2duaXRvOmdyb3VwcyI6WyJTVVBFUlZJU09SIiwiSURFTlRJVFkiLCJTVVBFUlVTRVIiXSwiZXZlbnRfaWQiOiIyMDEzODUzYS0zNTIwLTQ1YzMtOWEyNC0xYWE1YjQyOGE5OTkiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIiwiYXV0aF90aW1lIjoxNjExMTU0NjE3LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9vNzc3QzFKR1AiLCJleHAiOjE2MTExNTgyMTgsImlhdCI6MTYxMTE1NDYxOCwianRpIjoiNjlkOGMxMWYtMzk2ZS00Zjk5LWJjYTktYzZmZjVlNWJlNmMwIiwiY2xpZW50X2lkIjoiM2wxNjIyamM2ZXRkZHViYTdtNnZ2OTlzMzAiLCJ1c2VybmFtZSI6IjAyM2RkY2YxLTZkYjQtNGUwMS05MDY5LTM1OWRlYmY2NmI4MSJ9.hMzBJoUJqWdfh5Q2BFZgnE2I-1qgfe6EVawzLJO8jnq2_hbJ2lzUG64CrU7jd0bUh-_NUFAP-n19TWRxy2xzsxDvFz724JcB2udxJsL_FEkaN2JeNJ7Gl2ivQhyJ3vQlVuyfw4ZyV3GsWJC63nGpZ6AsD98dE7C8aCFpPjYO7C5iP3MGwBPRR9uhwr7--ghjEesRFiqANPYcfVUWEpUsZtbWHTOOKraqvc6GI8zPngI4T2e20WoebwaxDci7yzAIx-ONZQ84JJVGbzSCy5r6q9zgMGpILsk_cmOh-w1lGJBYF-k8dPNRWhBp3Q0H-T46P6pyVtC9cibx-wmaImVnvg"
      )
    );
    // yield success(user.attributes['custom:id']);
    // success(userId);

    // userId
    // yield put(
    //   snackbarVisible({
    //     msg: `${STRINGS.WELCOME} ${user.attributes.name}`,
    //     bgColor: SNACKBAR_COLOR.bgGreen
    //   })
    // );
    // }

    yield put(stopLoader());

  } catch (error) {
    console.log("error", error);
    // yield put(
    //   snackbarVisible({
    //     msg: error && error.message,
    //     bgColor: SNACKBAR_COLOR.bgRed
    //   })
    // );
    yield put(stopLoader());
  }
}

function* signOut() {
  yield put(startLoader());
  try {
    yield Auth.signOut();
    yield put(setAuthorization(null));
    yield put(stopLoader());
  } catch (error) {
    // yield put(
    //   snackbarVisible({
    //     msg: error && error.message,
    //     bgColor: SNACKBAR_COLOR.bgRed
    //   })
    // );
    yield put(stopLoader());
  }
}

function* AuthSaga() {
  yield all([
    takeLatest(SET_AUTHORIZATION, setUserToken),
    takeLatest(CHECK_LOGIN, checkAdminLogin),
    takeLatest(SIGN_OUT, signOut)
  ]);
}

export default AuthSaga;
