import { all, fork } from 'redux-saga/effects';
import AuthSaga from './auth';
import MenuSaga from './menu';
import SensorSaga from "./sensor";

function* dataSaga() {
    yield all([
        fork(AuthSaga),
        fork(MenuSaga),
        fork(SensorSaga)
    ]);
}

export default dataSaga;