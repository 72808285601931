import React from 'react';
import './styles.scss'
// import { Container } from './styles';

const FilterSearch = () => {
  return (
    <div className="content_wrapper">
      <div className="form-group " style={{ margin: "20px" }}>
        <div class="row">
          <div class="col-sm-3">
            <div class="select_join" style={{ marginLeft: "10px" }}>
              <select name="txtCountry">
                <option>-- All Customers --</option>
                <option value="1">Georgia</option>
                <option value="2">Afghanistan</option>
              </select>
            </div>
          </div>
          {/* <div class="col-sm-2">
              <div class="select_join" style={{ marginLeft: "10px" }}>
                <select name="txtCountry">
                  <option>-- All Status --</option>
                  <option value="1">Georgia</option>
                  <option value="2">Afghanistan</option>
                </select>
              </div>
            </div> */}
          <div class="col-sm-2">
            <input
              type="date"
              id="start"
              name="trip-start"
              className="form_fields"
              placeholder="start date"
            />
          </div>
          <div class="col-sm-2">
            <input
              type="date"
              id="start"
              name="trip-start"
              className="form_fields"
              placeholder="start date"
            />
          </div>
          <div class="col-sm-3" onClick={e => e.preventDefault()}>
            <form class="example">
              <input type="text" placeholder="Search.." name="search" />
              <button type="button">
                <i class="fa fa-search" style={{ color: "gray" }}></i>
              </button>
            </form>
          </div>
          <div class="col-sm-2">
            <button type="button" class="btn btn-success btn-lg float-right add-btn"><i class="fa fa-plus-circle" aria-hidden="true"></i>
            Add</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterSearch;