import { connect } from "react-redux";
import Login from "./screen";
const { checkLogin, setAuthorization } = require(`../../redux/actions`);

const mapStateToProps = state => {
  return {
    userToken: state.CommonReducer.userToken
  };
};
const mapDispatchToProps = dispatch => {
  return {
    checkLogin: (credentials, success) =>
      dispatch(checkLogin(credentials, success)),
    setAuthorization: userToken => dispatch(setAuthorization(userToken))
  };
};

const LoginScreen = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginScreen;
