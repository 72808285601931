import { SNACKBAR_VISIBLE, SNACKBAR_INVISIBLE } from "../actions";

const initialCommonState = {
  snackbar: null
};

const SnackbarReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case SNACKBAR_VISIBLE:
      return {
        ...state,
        snackbar: true,
        snackbarMessage: action.response.msg,
        snackbarBgColor: action.response.bgColor,
        apiStatus: action.response.status
      };
    case SNACKBAR_INVISIBLE:
      return {
        ...state,
        snackbar: false
      };
    default:
      return state;
  }
};

export default SnackbarReducer;
