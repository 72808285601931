import React from "react";
import Footer from "../atoms/footer";
import SideBar from "../atoms/sidebar";
import Header from "../molecules/header";

const ScreenHOC = ({ signOut, userData, children,menuItems,onClickAction,location }) => (
  <div>
    <Header signOut={signOut} userData={userData} />
    <SideBar
      profileImage={require("../../assets/img/user2-160x160.jpg").default}
      name={userData?.name}
      menuItems={menuItems}
      onClickAction={onClickAction}
      location={location}
    />
    {children}
    {/* <Footer /> */}
  </div>
);

export default ScreenHOC;
