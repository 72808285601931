export const ROUTES = {
  ROOT: "/",
  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  REDIRECT: "/redirect",
  FACILITY: '/dashboard/facility',
  MENU: "/dashboard/menu",
  TOP_LIST: "/dashboard/top-lists",
  REVIEWS: "/dashboard/reviews",
  EMAILS: "/dashboard/emails",
  SEND_MESSAGE: "/dashboard/send-message",
  STATS: "/dashboard/stats",
  LOGOUT: "/logout",
  SENSOR: '/dashboard/sensor',
  ZONES: '/dashboard/zones'
};
