// SideBar.js
import React, { useState } from "react";
import "./styles.scss";
import { createBrowserHistory } from "history";
import { ROUTES } from "../../../shared/constants";
const hist = createBrowserHistory();
const innerMenu = items => (
  <ul className="treeview-menu">
    {items.map((item, index) => (
      <li className="active" key={index}>
        <a href="/">
          <i className="fa fa-circle-o"></i>
          {item}
        </a>
      </li>
    ))}
  </ul>
);

const SideBar = ({ profileImage, name,menuItems,onClickAction,location }) => {
  const [searchString, setSearchString] = useState();
  // const [se]
  // var style = {
  //   display: "block"
  // };
  return (
    <aside className="main-sidebar">
      {/* sidebar: style can be found in sidebar.less */}
      <section className="sidebar">
        <h3>ACTIVE WITNESS</h3>
        {/* Sidebar user panel */}
        {/* <div className="user-panel">
          <div className="pull-left image">
            <img
              src="dist/img/user2-160x160.jpg"
              className="img-circle"
              alt="User Image"
            />
          </div>
          <div className="pull-left info">
            <p>{name}</p>
            <a>
              <i className="fa fa-circle text-success"></i> Online
            </a>
          </div>
        </div> */}
        {/* search form */}
        <form action="#" method="get" className="sidebar-form">
          {/* <div className="input-group">
            <input
              type="text"
              name="q"
              className="form-control"
              placeholder="Search..."
              value={searchString}
              onChange={(e)=>setSearchString(e.target.value)}
            />
            <span className="input-group-btn">
              <button
                type="submit"
                name="search"
                id="search-btn"
                className="btn btn-flat"
              >
                <i className="fa fa-search"></i>
              </button>
            </span>
          </div> */}
        </form>
        {/* /.search form */}
        {/* sidebar menu: : style can be found in sidebar.less */}
        <ul className="sidebar-menu">
          <li className="header">MAIN NAVIGATION</li>
          {menuItems.map((item,index)=>(<li key={index+''} className={`${location==item.ROUTE&&"active"} treeview`} onClick={()=>onClickAction(item.ROUTE)}>
            <a>
              <i className={`fa ${item.icon}`}></i>
              <span>{item.tag}</span>
              {/* <i className="fa fa-angle-left pull-right"></i> */}
            </a>
            {/* {innerMenu(["Dashboard v1", " Dashboard v2"])} */}
          </li>))}
        </ul>
      </section>
      {/* /.sidebar */}
    </aside>
  );
};
export default SideBar;
