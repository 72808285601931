import React from 'react';
import './styles.scss'
const Modal = ({ children, description, }) => {
  return (
    <div id="myModal" class="modal" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;