import React, { useContext } from "react";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import CustomTable from "../../components/atoms/custom-table";
import { ContextApp } from "../../utils/ContextApp";
import API from "../../utils/Api";
import Modal from '../../components/atoms/modal'
import ConfirmModal from '../../components/atoms/confirmation-modal'
const Dashboard = ({ getSensorData, userData, stopLoader, startLoader }) => {
  // console.log("userData", userData);
  const { user } = useContext(ContextApp);
  const [modalOpen, setModalOpen] = useState(false)
  const fetchDataSensors = async () => {
    startLoader();
    try {
      const data = await API.users.getUserById(userData.facilityOwner.identityIds);
      console.log("data", data);
      const tableData = [];
      const tempData = data.filter((item, index) => {
        let { id = "-", name = "-", description = "-", zoneId } = item;
        if (id === undefined || id === null) {
          id = "-";
        } else if (name === undefined || name === null) {
          name = "-";
        } else if (description === undefined || description === null) {
          description = "-";
        } else if (zoneId === undefined || zoneId === null) {
          zoneId = "-";
        }
        const zone = userData?.zones.filter((item) => {
          if (item.id == zoneId) {
            return item?.description;
          }
        });

        tableData.push({ id, name, description, zoneId: zone[0]?.description });
      });
      setData([...tableData]);
      stopLoader();
    } catch (error) {
      // snackbarVisible({
      //   msg: `${error?.msg}`,
      //   bgColor: SNACKBAR_COLOR.bgGreen
      // })
      console.log(error);
      stopLoader();
    }
  };

  useEffect(() => {
    // fetchDataSensors();
  }, [JSON.stringify(userData)]);

  const [data, setData] = useState([{
    company: "Los Angeles Lakers", date: "5-Jan-20", facility: 2, zones: 2, users: 6, sensor: 5, email: "Coyote@spurs.com"
  },
  { company: "Dallas Mavericks", date: "18-Feb-20", facility: 2, zones: 2, users: 6, sensor: 5, email: "Coyote@spurs.com" },
  { company: "Houston Rockets", date: "15-Nov-20", facility: 2, zones: 2, users: 6, sensor: 5, email: "Coyote@spurs.com" },
  {
    company: "Golden State Warriors", date: "5-Jan-20", facility: 2, zones: 2, users: 6, sensor: 5, email: "Coyote@spurs.com"
  }]);
  const [header, setHeader] = useState([
    "Company/Organization",
    "Account Created",
    "Facilities",
    "Zones",
    "User in Account",
    "Sensors",
    "Admin",
    "Action"
  ]);

  const sort = useCallback((column, order, data) => {
    const tempData = data;
    tempData.sort((a, b) => {
      const getSelectedColumn = Object.keys(a)[column];
      if (!getSelectedColumn) return;
      let value1 = a[getSelectedColumn] && a[getSelectedColumn]?.toLowerCase && a[getSelectedColumn]?.toLowerCase()
      let value2 = b[getSelectedColumn] && b[getSelectedColumn]?.toLowerCase && b[getSelectedColumn]?.toLowerCase();
      return order === 1
        ? value1?.charCodeAt(0) - value2?.charCodeAt(0)
        : value2?.charCodeAt(0) - value1?.charCodeAt(0);
    });
    setData([...tempData]);
  }, []);

  const searching = useCallback((searchedString = "2"?.toLowerCase(), data) => {
    const tempData = data.filter((item, index) => {
      const valueInItem = Object.values(item);
      if (
        valueInItem
          ?.join(" ")
          ?.toLowerCase()
          ?.search(searchedString?.toString()?.trim()?.toLowerCase()) !== -1
      ) {
        return item;
      }
    });
    setData([...tempData]);
  }, []);
  return (
    <div>
      <CustomTable
        data={data}
        header={header}
        sort={sort}
        searching={() => { }}
      />
      { modalOpen && <Modal>
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" onClick={() => setModalOpen(false)}>&times;</button>
          <h4 class="modal-title">Modal Header</h4>
        </div>
        <div class="modal-body">
          <p>Are you sure? You want to delete this row</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Confirm</button>
          <button type="button" class="btn btn-default" data-dismiss="modal" onClick={() => setModalOpen(false)}>Cancel</button>
        </div>
      </Modal>}
    </div>
  );
};

export default Dashboard;
