import { takeLatest, all, put } from "redux-saga/effects";
import {
  SET_AUTHORIZATION,
  CHECK_LOGIN,
  setAuthorization,
  SIGN_OUT,
  GET_SENSOR_DATA,
  setData
} from "../actions";
import API from '../../utils/Api';
import {
  startLoader,
  stopLoader,
  snackbarVisible,
  snackbarInvisible
} from "../actions";
import { SNACKBAR_COLOR, STRINGS } from "../../shared/constants";
function* setUserToken({ userToken }) {
  try {
  } catch (error) {
    return;
  }
}

function* getSensorData({ payload }) {
  yield put(startLoader());
  try {
    const response = yield API.sensors.getSensorsById(payload)
    // console.log("response",response);
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
  }
}

function* signOut() {
  yield put(startLoader());
  try {
    yield put(stopLoader());
  } catch (error) {
    yield put(stopLoader());
  }
}

function* SensorSaga() {
  yield all([
    takeLatest(GET_SENSOR_DATA, getSensorData)
  ]);
}

export default SensorSaga;
