export const SET_AUTHORIZATION = "SET_AUTHORIZATION";
export const CHECK_LOGIN = "CHECK_LOGIN";
export const SIGN_OUT = "SIGN_OUT";
export const USER_DATA="USER_DATA";

export const setAuthorization = (userToken) => {
  return {
    type: SET_AUTHORIZATION,
    userToken
  };
};

export const setUserData=(payload)=>{
  // console.log("payload",payload);
  return{
    type:USER_DATA,
    payload
  }
}

export const checkLogin = (credentials, success) => {
  return {
    type: CHECK_LOGIN,
    credentials,
    success
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT
  };
};
