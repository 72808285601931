import { Auth } from "aws-amplify";

const SERVER_URL = "prd.activewitness.com";
const BASE_URL = `https://${SERVER_URL}/api/`;

async function makeRequest(endpoint = "/", method = "GET", payload = {}) {
  const sessionToken = await Auth.currentSession();
  let authorization = sessionToken.accessToken["jwtToken"];
  const contentType = "application/json";
  const fetchAttributes = {
    method,
    headers: {
      Accept: "*/*",
      "Cache-Control": "no-cache",
      Host: SERVER_URL,
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
      Authorization: `Bearer ${authorization}`,
      "Content-Type": contentType
    }
  };

  if (method === "POST" || method === "PUT" || method === "DELETE") {
    fetchAttributes.body = JSON.stringify(payload);
  }

  return await fetch(`${BASE_URL}${endpoint}`, fetchAttributes)
    .then(response => {
      // console.log("fetchAttributes => ", fetchAttributes.body);
      // console.log(method, response.status, `${BASE_URL}${endpoint}`);
      // if (!response.ok) {
      //   // throw Error(`error service ${BASE_URL}${sufix} ${JSON.stringify(response.text())}`);
      //   return response.text();
      // }
      return response.json();
    })
    .then(jsonresponse => {
      // console.log("response in api", jsonresponse);
      // if(jsonresponse.status === 'SystemException' && jsonresponse.msg?.includes('Optimistic'))
      // {
      //   Alert.alert(
      //     'Error',
      //     `Entity is already being edited, please wait for while and start over again.`,
      //     [{ text: 'OK',
      //     }],
      //     { cancelable: false },
      //   );
      //   return jsonresponse
      // }else{
      return jsonresponse;
      // }
    })
    .catch(err => {
      // console.log("error with status ", err);
    });
}

async function makeRequestMultipart(sufix = "/", method = "PUT", payload = {}) {
  const sessionToken = await Auth.currentSession();
  let authorization = sessionToken.accessToken["jwtToken"];
  // console.log("authorization",authorization);
  const myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${authorization}`);
  delete myHeaders["Content-Type"];
  delete myHeaders["Content-Length"];
  const requestOptions = {
    method: method,
    headers: myHeaders,
    body: payload,
    redirect: "follow"
  };

  return await fetch(`${BASE_URL}${sufix}`, requestOptions)
    .then(response => {
      if (!response.ok) {
        return response.text();
      }
      return response.json();
    })
    .then(jsonresponse => jsonresponse)
    .catch(err => {
      console.log("error with status ", err);
    });
}

const API = {
  users: {
    async getUserById(id) {
      const response = await makeRequest(`crud/Identity?id=${id}`, `GET`, {});
      return response.data;
    },
    async logs(id) {
      const response = await makeRequest(`getEventsById?id=${id}`, `GET`, {});
      return response.data;
    },
    async getIdentitiesByFacilityId(idFacility) {
      const response = await makeRequest(
        `getIdentitiesByFacilityId?id=${idFacility}`,
        `GET`
      );
      return response.data;
    },
    async getSupervisorsByFacilityOwnerId(idFacility) {
      const response = await makeRequest(
        `getSupervisorsByFacilityOwnerId?id=${idFacility}`,
        `GET`
      );
      return response.data;
    },
    async createUser(data) {
      const response = await makeRequest(`crud/Identity`, `PUT`, data);
      return response;
    },
    async updateUser(data, id) {
      const response = await makeRequest(
        `crud/Identity?id=${id}`,
        `POST`,
        data
      );
      return response;
    },
    async deleteUser(data) {
      const response = await makeRequest(
        `crud/Identity?id=${data.id}`,
        `DELETE`
      );
      return response;
    },
    async resendQrCodeByIdentityId(id) {
      const response = await makeRequest(
        `resendQRCodeByIdentityId?id=${id}`,
        `GET`
      );
      return response;
    },
    async getUserImage(id) {
      const response = await makeRequest(`crud/Picture?id=${id}`, `GET`);
      return response.data;
    },
    async resendTempPasswordByIdentityId(id) {
      const response = await makeRequest(
        `resendTempPasswordByIdentityId?id=${id}`,
        `GET`
      );
      return response;
    }
  },
  sensors: {
    async getSensorsById(id) {
      if (!id || !id.length) return [];
      const response = await makeRequest(`crud/Sensor?id=${id}`, `GET`);
      return response.data;
    },
    async createSensor(data) {
      const response = await makeRequest(`crud/Sensor`, `PUT`, data);
      return response;
    },
    async updateSensor(data) {
      const response = await makeRequest(
        `crud/Sensor?id=${data.id}`,
        `POST`,
        data
      );
      return response;
    }
  },
  zones: {
    async getZonesByFacilityId(idFacility) {
      const response = await makeRequest(
        `getZonesByFacilityId?id=${idFacility}`,
        `GET`
      );
      return response.data;
    },
    async getZonesByUser(idUser) {
      const response = await makeRequest(
        `getZonesByIdentityId?id=${idUser}`,
        `GET`
      );
      return response.data;
    },
    async getZonesByIds(id) {
      const response = await makeRequest(`crud/Zone?id=${id}`, `GET`);
      return response.data;
    },
    async createZone(data) {
      const response = await makeRequest(`crud/Zone`, `PUT`, data);
      return response;
    },
    async updateZone(data) {
      const response = await makeRequest(
        `crud/Zone?id=${data.id}`,
        `POST`,
        data
      );
      return response;
    },
    async getZonesByFacilityOwnerId(id) {
      const response = await makeRequest(
        `getZonesByFacilityOwnerId?id=${id}`,
        `GET`
      );
      return response.data;
    }
  },
  facilities: {
    async getFacilitiesByFacilityOwnerId(facilityOwnerId) {
      const response = await makeRequest(
        `getFacilitiesByFacilityOwnerId?id=${facilityOwnerId}`,
        `GET`
      );
      return response.data;
    },

    async getFacilitiesByIDs(idsFacilities) {
      if (!idsFacilities || !idsFacilities.length) return [];
      const response = await makeRequest(
        `crud/Facility?id=${idsFacilities}`,
        `GET`
      );
      return response.data;
    },

    async createFacility(data) {
      const response = await makeRequest(`crud/Facility`, `PUT`, data);
      return response;
    },

    async updateFacility(data) {
      const response = await makeRequest(
        `crud/Facility?id=${data.id}`,
        `POST`,
        data
      );
      return response;
    }
  },
  facilityOwner: {
    async createFacilityOwn(data) {
      const response = await makeRequest(`crud/FacilityOwner`, `PUT`, data);
      return response;
    },
    async updateFacilityOwn(data) {
      const response = await makeRequest(
        `crud/FacilityOwner?id=${data.id}`,
        `POST`,
        data
      );
      return response;
    },
    async getFacilityOwner(id) {
      const response = await makeRequest(`crud/FacilityOwner?id=${id}`, `GET`);
      return response.data;
    },
    async getFacilityOwners() {
      const response = await makeRequest(`getFacilityOwners`, `GET`);
      return response.data;
    }
  },
  requestAccess: {
    async getPendingApprovalsByIdentityId(id) {
      const response = await makeRequest(
        `getPendingApprovalsByIdentityId?id=${id}`,
        "GET"
      );
      return response.data;
    },

    async getApprovalData(id) {
      const response = await makeRequest(`crud/ApprovalData?id=${id}`, `GET`);
      return response.data;
    },

    async request(data) {
      const response = await makeRequest(`crud/Approval`, `PUT`, data);
      return response.data;
    },

    async approvePhoto(data) {
      // console.log("data", data);
      const response = await makeRequestMultipart(`crud/Approval`, `PUT`, data);
      if (response.data) {
        return response.data;
      } else {
        const resParse = JSON.parse(response);
        return resParse;
      }
    },

    async approvePhotoUser(data) {
      const response = await makeRequestMultipart(`crud/Approval`, `PUT`, data);
      if (response.data) {
        return response.data;
      } else {
        const resParse = JSON.parse(response);
        return resParse;
      }
    },

    async confirmIdentity(id, data) {
      const response = await makeRequestMultipart(
        `confirmIdentity?id=${id}`,
        `POST`,
        data
      );
      if (response.data) {
        return response.data;
      } else {
        const resParse = JSON.parse(response);
        return resParse;
      }
    },

    async approve({ id, reason }) {
      const response = await makeRequest(
        `approveDenyApproval?id=${id}&reason=${reason}&approve=yes`,
        `GET`
      );
      return response.data;
    },

    async deny({ id, reason }) {
      const response = await makeRequest(
        `approveDenyApproval?id=${id}&reason=${reason}&approve=no`,
        `GET`
      );
      return response.data;
    }
  }
};

export default API;
