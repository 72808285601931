import React from 'react';
import ReactPaginate from "react-paginate";

// import { Container } from './styles';

const Pagination = ({ pageIndex, onPageChange = () => { }, pageSize }) => {
  // console.log("pageSize", pageSize);
  return (
    <div className="content_wrapper pt-20">
      <div className="row pagination_">
        <div className="col-sm-12">
          <ReactPaginate
            previousLabel="prev"
            nextLabel="next"
            // forcePage={curPage}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageSize}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName="page-item"
            subContainerClassName="page-link"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
}

export default Pagination;