import React from "react";
import Router from "./routers/root-router";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Amplify from "aws-amplify";
import awsMobile from "./aws-exports";
import {AppProvider,ContextApp} from './utils/ContextApp'
Amplify.configure(awsMobile);

const App = () => (
  <Provider store={store}>
    <AppProvider>
    <ContextApp.Consumer>
          {({ user }) => (
            <Router user={user} />
          )}
        </ContextApp.Consumer>
    </AppProvider>
  </Provider>
);

export default App;
