import React from "react";
import { createBrowserHistory } from "history";
import { Route, Switch, Redirect, Router,BrowserRouter } from "react-router-dom";
import Login from "../../views/loginScreen/";
import { connect } from "react-redux";
import { Loader } from "../../components/atoms/loader";
import { Snackbar } from "../../components/atoms/snackbar";
import { ROUTES } from "../../shared/constants";
import AppRouter from "../app-router";
import Facility from '../../views/facility'

const hist = createBrowserHistory();

const Rootrouter = ({
  snackbarVisibility,
  loader,
  userToken,
  setAuthorization
}) => {
  return (
    <BrowserRouter history={hist}>
      {snackbarVisibility && <Snackbar />}
      {loader && <Loader />}
      <Switch>
        <Route
          exact
          path={ROUTES.ROOT}
          render={(props) => {
            return userToken ? (
              <AppRouter {...props} />
            ) : (
              <Redirect to={ROUTES.LOGIN} />
            );
          }}
        />
        <Route path={ROUTES.LOGIN} component={Login} />
        <Route
          path={ROUTES.REDIRECT}
          render={() => {
            setAuthorization(userToken);
            return (
              <Redirect
                push
                to={{
                  pathname: userToken ? ROUTES.DASHBOARD : ROUTES.LOGIN
                }}
              />
            );
          }}
        />
        <Route
          path={ROUTES.DASHBOARD}
          render={props => {
            return userToken ? (
              <AppRouter {...props} />
            ) : (
              <Redirect to={ROUTES.ROOT} />
            );
          }}
        />
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {
    userToken: state.CommonReducer.userToken,
    loader: state.LoaderReducer.loader,
    snackbarVisibility: state.SnackbarReducer.snackbar
  };
};
const mapDispatchToProps = dispatch => {
  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(Rootrouter);
