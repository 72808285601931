export const FETCH_CATEGORIES_DESIGN_LIST = 'FETCH_CATEGORIES_DESIGN_LIST';
export const SET_CATEGORIES_DESIGN_LIST = 'SET_CATEGORIES_DESIGN_LIST';
export const SAVE_CATEGORY_CHANGE = 'SAVE_CATEGORY_CHANGE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const SAVE_SPIN_DESIGNS = 'SAVE_SPIN_DESIGNS';
export const UPDATE_SPIN_DESIGN = 'UPDATE_SPIN_DESIGN';

export const fetchCategoriesDesignList = (appType) => {
    return {
        type: FETCH_CATEGORIES_DESIGN_LIST,
        appType
    };
};

export const setCategoriesDesignList = (list) => {
    return {
        type: SET_CATEGORIES_DESIGN_LIST,
        list
    }
};

export const saveCategoryChange = (data) => {
    return {
        type: SAVE_CATEGORY_CHANGE,
        data
    }
};

export const saveSpinDesignChange = (data) => {
    return {
        type: UPDATE_SPIN_DESIGN,
        data
    }
}

export const uploadFile = (file, success) => {
    return {
        type: UPLOAD_FILE,
        file,
        success
    }
};

export const saveSpinDesigns = (design) => {
    return {
        type: SAVE_SPIN_DESIGNS,
        design
    }
}
