import React from "react";
import FilterSearch from '../filter-search';
let order = 1;
let currentpage = 1;
const CustomTable = ({ header, data, sort, searching }) => {
  // console.log("data", data);
  return (
    <React.Fragment>
      <FilterSearch />
      <div
        id="example2_wrapper"
        class="dataTables_wrapper form-inline dt-bootstrap content-wrapper"
      >
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6"></div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table
              id="example2"
              class="table table-bordered table-hover dataTable"
              role="grid"
              aria-describedby="example2_info"
            >
              <thead style={{ backgroundColor: "#ccc" }}>
                <tr role="row" style={{ color: "white" }}>
                  {header.map((item, index) => (
                    <th
                      key={index}
                      // class="sorting_desc"
                      className="text-center"
                      tabindex="0"
                      aria-controls="example2"
                      rowspan="1"
                      colspan="1"
                      // aria-label="CSS grade: activate to sort column ascending"
                      aria-sort="descending"
                      onClick={() => {
                        sort(index, order, data);
                        order = order * -1;
                      }}
                    >
                      <div class="h3 item-style">{item}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  const tableData = Object.values(item);
                  // console.log("tableData", tableData);
                  return (
                    // null
                    <tr role="row" class="odd">
                      {tableData.map((tableItem, index) => (
                        <td
                          // key={tableData + index}
                          onClick={() => searching(tableItem, data)}
                          className="text-center table_des"
                        >
                          {tableItem || "-"}
                        </td>
                      ))}
                      <td className="text-center">
                        <span>
                          <button type="button" class="btn btn-primary">
                            Edit
                          </button>
                          <span className="marginLeft">
                            <button type="button" class="btn btn-danger">
                              Delete
                            </button>
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* <tfoot>
            <tr>
              <th rowspan="1" colspan="1">
                Rendering engine
              </th>
              <th rowspan="1" colspan="1">
                Browser
              </th>
              <th rowspan="1" colspan="1">
                Platform(s)
              </th>
              <th rowspan="1" colspan="1">
                Engine version
              </th>
              <th rowspan="1" colspan="1">
                CSS grade
              </th>
            </tr>
          </tfoot> */}
            </table>
          </div>
        </div>

        {/* <div class="row">
      <div class="col-sm-5">
        <div
          class="dataTables_info"
          id="example2_info"
          role="status"
          aria-live="polite"
        >
          Showing 31 to 40 of 57 entries
        </div>
      </div>
      <div class="col-sm-7">
        <div
          class="dataTables_paginate paging_simple_numbers"
          id="example2_paginate"
        >
          <ul class="pagination">
            <li class="paginate_button previous" id="example2_previous">
              <a
                href="#"
                aria-controls="example2"
                data-dt-idx="0"
                tabindex="0"
              >
                Previous
              </a>
            </li>
            <li class="paginate_button ">
              <a
                href="#"
                aria-controls="example2"
                data-dt-idx="1"
                tabindex="0"
              >
                1
              </a>
            </li>
            <li class="paginate_button ">
              <a
                href="#"
                aria-controls="example2"
                data-dt-idx="2"
                tabindex="0"
              >
                2
              </a>
            </li>
            <li class="paginate_button ">
              <a
                href="#"
                aria-controls="example2"
                data-dt-idx="3"
                tabindex="0"
              >
                3
              </a>
            </li>
            <li class="paginate_button active">
              <a
                href="#"
                aria-controls="example2"
                data-dt-idx="4"
                tabindex="0"
              >
                4
              </a>
            </li>
            <li class="paginate_button ">
              <a
                href="#"
                aria-controls="example2"
                data-dt-idx="5"
                tabindex="0"
              >
                5
              </a>
            </li>
            <li class="paginate_button ">
              <a
                href="#"
                aria-controls="example2"
                data-dt-idx="6"
                tabindex="0"
              >
                6
              </a>
            </li>
            <li class="paginate_button next" id="example2_next">
              <a
                href="#"
                aria-controls="example2"
                data-dt-idx="7"
                tabindex="0"
              >
                Next
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> */}
      </div>
    </React.Fragment>
  );
};

export default CustomTable;
