export const STRINGS = {
  EMAIL: "Email",
  PASSWORD: "Password",
  LOGIN: "Login",
  MENU: "Menu",
  TOP_LIST: "Top Lists",
  REVIEWS: "Reviews",
  EMAILS: "Emails",
  SEND_MESSAGE: "Send Message",
  STATS: "Stats",
  LOGOUT: "Logout",
  UPLOAD_BIG_ICON: "Upload Big Icon",
  LEFT: "Left",
  RIGHT: "Right",
  WELCOME: "Welcome"
};
