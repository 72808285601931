export const SNACKBAR_VISIBLE = "SNACKBAR_VISIBLE";
export const SNACKBAR_INVISIBLE = "SNACKBAR_INVISIBLE";

export const snackbarVisible = response => {
  return {
    type: SNACKBAR_VISIBLE,
    response
  };
};

export const snackbarInvisible = () => {
  return {
    type: SNACKBAR_INVISIBLE
  };
};
