import { combineReducers } from "redux";
import CommonReducer from "./common";
import MenuReducer from "./menu";
import LoaderReducer from "./loader";
import SnackbarReducer from "./snackbar";
import SensorReducer from './sensor';

const reducers = combineReducers({
  CommonReducer,
  MenuReducer,
  LoaderReducer,
  SnackbarReducer,
  SensorReducer
});

export default reducers;
