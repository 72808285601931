import React from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { snackbarInvisible } from "../../../redux/actions";
const SnackbarComponent = ({ msg, snackbarInvisible = () => {}, bgColor }) => (
  <div
    id="snackbar"
    style={{ backgroundColor: bgColor }}
    onAnimationEnd={snackbarInvisible}
  >
    {msg}
  </div>
);

const mapStateToProps = state => {
  return {
    msg: state.SnackbarReducer.snackbarMessage,
    bgColor: state.SnackbarReducer.snackbarBgColor
  };
};
const mapDispatchToProps = dispatch => {
  return {
    snackbarInvisible: () => dispatch(snackbarInvisible())
  };
};
export const Snackbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarComponent);
