import React from "react";
import HeaderNotifications from "../../atoms/header-notification/header-notifications";
import HeaderProfile from "../../atoms/header-profile";
const menu_icons = [
  { icons: "fa fa-envelope-o" },
  { icons: "fa fa-bell-o" },
  { icons: "fa fa-flag-o" }
];

const menuPush = () => {
  var body = document.body;
  if (body.clientWidth > 768) {
    if (body.className.indexOf("sidebar-collapse") === -1) {
      body.className += " sidebar-collapse";
    } else {
      body.className = body.className.replace(" sidebar-collapse", "");
    }
  } else {
    if (body.className.indexOf("sidebar-open") === -1) {
      body.className += " sidebar-open";
    } else {
      body.className = body.className.replace(" sidebar-open", "");
    }
  }
};
const Header = props => (
  <header className="main-header">
    <a className="logo">
      <span className="logo-mini">
        <b>A</b>W
      </span>
      <span className="logo-lg">
        {/* <b>Admin</b>LTE */}
      </span>
    </a>
    <nav className="navbar navbar-static-top">
      <a
        className="sidebar-toggle"
        data-toggle="push-menu"
        role="button"
        onClick={menuPush}
      >
        <span className="sr-only">Toggle navigation</span>
      </a>
      <div className="navbar-custom-menu">
        <ul className="nav navbar-nav">
          {/* {menu_icons.map(({ icons }, index) => (
            <HeaderNotifications key={index + ""} icons={icons} />
          ))} */}
          <HeaderProfile {...props} />
          {/* <li>
            <a data-toggle="control-sidebar">
              <i class="fa fa-gears"></i>
            </a>
          </li> */}
        </ul>
      </div>
    </nav>
  </header>
);

export default Header;
