export const GET_SENSOR_DATA="GET_SENSOR_DATA"
export const SET_SENSOR_DATA="SET_SENSOR_DATA"


export const getSensorData=(payload)=>{
    return{
        type:GET_SENSOR_DATA,
        payload
    }
}


export const setSensorData=(data)=>{
    return{
        type:SET_SENSOR_DATA,
        data
    }
}
