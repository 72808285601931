import { STRINGS } from "./strings";
import { ROUTES } from "./routes";
import { DEFAULT_DRAWER_LOGO, LOGOUT_ICON } from "./icons";

export const EMAIL_REGX = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DRAWER_ITEMS = [
  {
    tag: STRINGS.MENU,
    alt: "drawer-icon",
    routeUrl: ROUTES.MENU,
    selectedIcon: DEFAULT_DRAWER_LOGO,
    unSelectedIcon: DEFAULT_DRAWER_LOGO
  },
  {
    tag: STRINGS.TOP_LIST,
    alt: "drawer-icon",
    routeUrl: ROUTES.TOP_LIST,
    selectedIcon: DEFAULT_DRAWER_LOGO,
    unSelectedIcon: DEFAULT_DRAWER_LOGO
  },
  {
    tag: STRINGS.REVIEWS,
    alt: "drawer-icon",
    routeUrl: ROUTES.REVIEWS,
    selectedIcon: DEFAULT_DRAWER_LOGO,
    unSelectedIcon: DEFAULT_DRAWER_LOGO
  },
  {
    tag: STRINGS.EMAILS,
    alt: "drawer-icon",
    routeUrl: ROUTES.EMAILS,
    selectedIcon: DEFAULT_DRAWER_LOGO,
    unSelectedIcon: DEFAULT_DRAWER_LOGO
  },
  {
    tag: STRINGS.SEND_MESSAGE,
    alt: "drawer-icon",
    routeUrl: ROUTES.SEND_MESSAGE,
    selectedIcon: DEFAULT_DRAWER_LOGO,
    unSelectedIcon: DEFAULT_DRAWER_LOGO
  },
  {
    tag: STRINGS.STATS,
    alt: "drawer-icon",
    routeUrl: ROUTES.STATS,
    selectedIcon: DEFAULT_DRAWER_LOGO,
    unSelectedIcon: DEFAULT_DRAWER_LOGO
  },
  {
    tag: STRINGS.LOGOUT,
    alt: "drawer-icon",
    routeUrl: ROUTES.LOGOUT,
    selectedIcon: LOGOUT_ICON,
    unSelectedIcon: LOGOUT_ICON
  }
];

export const MENU_ITEMS = [
  { tag: "Customer", ROUTE: ROUTES.DASHBOARD, icon: "fa-dashboard" },
  { tag: "Facility", ROUTE: ROUTES.FACILITY, icon: "fa-align-justify" },
  { tag: "Sensor", ROUTE: ROUTES.SENSOR, icon: "fa-bullseye" },
  { tag: "Zones", ROUTE: ROUTES.ZONES, icon: "fa-area-chart" }
]

export const FACILITY = ['Facililty Name', 'Facililty Address', "Facility description", "Action"]
export const ZONES = ['Zone Name', 'Zone Facililty', "Action"]
export const LABELS = {
  login: STRINGS.LOGIN,
  email: STRINGS.EMAIL,
  password: STRINGS.PASSWORD,
  bigIconLabel: STRINGS.UPLOAD_BIG_ICON,
  left: STRINGS.LEFT,
  right: STRINGS.RIGHT
};

export const TOP_ICONS_ITEMS = [
  {
    dropzoneLabel: "Upload Left Icon"
  },
  {
    dropzoneLabel: "Upload Right Icon"
  }
];

export const BIG_ICON_ITEMS = {
  label: LABELS.bigIconLabel
};

export const IMAGE_ALIGNMENT_ITEMS = [
  {
    value: 1,
    label: LABELS.left
  },
  {
    value: 2,
    label: LABELS.right
  }
];

export const ELEMENT_ID = {
  welcomeText: "welcome-text",
  loginPage: "login-page",
  loginButton: "login-button",
  submitButton: "submit-button",
  challengePage: "challenge-page",
  challengesDiv: "challenges-div",
  challengeButton: "challenge-button",
  redirectingLink: "redirecting-link",
  profilePage: "profile-page",
  leaderboardPage: "leaderboard-page",
  inputWebUrl: "input-website-url",
  emailInput: "email-input",
  passwordInput: "password-input",
  challengeDetailPage: "challenge-detail-page",
  challengeDetailDiv: "challenge-detail-div",
  dropInput: "drop-input",
  customInput: "custom-input",
  default: "default"
};

export const KEY_CODES = {
  enterKey: 13,
  nine: 57,
  zero: 48,
  backSpace: 8
};

export const STATUS_CODE = {
  successful: 200
};

export const boldFontSizeOptions = [
  {
    label: "18 Pt",
    value: 18
  },
  {
    label: "22 Pt",
    value: 22
  },
  {
    label: "26 Pt",
    value: 26
  },
  {
    label: "30 Pt",
    value: 30
  },
  {
    label: "34 Pt",
    value: 34
  },
  {
    label: "38 Pt",
    value: 38
  },
  {
    label: "42 Pt",
    value: 42
  },
  {
    label: "46 Pt",
    value: 46
  },
  {
    label: "50 Pt",
    value: 50
  }
];

export const regularFontSizeOptions = [
  {
    label: "8 Pt",
    value: 8
  },
  {
    label: "10 Pt",
    value: 10
  },
  {
    label: "12 Pt",
    value: 12
  },
  {
    label: "14 Pt",
    value: 14
  },
  {
    label: "16 Pt",
    value: 16
  },
  {
    label: "18 Pt",
    value: 18
  },
  {
    label: "20 Pt",
    value: 20
  },
  {
    label: "22 Pt",
    value: 22
  },
  {
    label: "24 Pt",
    value: 24
  }
];

export const smallFontSizeOptions = [
  {
    label: "4 Pt",
    value: 4
  },
  {
    label: "6 Pt",
    value: 6
  },
  {
    label: "8 Pt",
    value: 8
  },
  {
    label: "10 Pt",
    value: 10
  },
  {
    label: "12 Pt",
    value: 12
  },
  {
    label: "14 Pt",
    value: 14
  },
  {
    label: "16 Pt",
    value: 16
  }
];

export const TOP_IDEAL_TEXT_STYLERS_ITEMS = [
  {
    inputLabel: "Change Bold Text",
    maxLength: 8,
    dropDownLabel: "Font Size",
    dropdownItems: [...boldFontSizeOptions]
  },
  {
    inputLabel: "Change Regular Text",
    maxLength: 15,
    dropDownLabel: "Font Size",
    dropdownItems: [...regularFontSizeOptions]
  },
  {
    inputLabel: "Change Small Text",
    maxLength: 25,
    dropDownLabel: "Font Size",
    dropdownItems: [...smallFontSizeOptions]
  }
];

export const TOP_TRUSTLY_TEXT_STYLERS_ITEMS = [
  {
    inputLabel: "Change Bold Text",
    maxLength: 8,
    dropDownLabel: "Font Size",
    dropdownItems: [...boldFontSizeOptions]
  },
  {
    inputLabel: "Change Regular Text",
    maxLength: 15,
    dropDownLabel: "Font Size",
    dropdownItems: [...regularFontSizeOptions]
  },
  {
    inputLabel: "Change Small Text",
    maxLength: 25,
    dropDownLabel: "Font Size",
    dropdownItems: [...smallFontSizeOptions]
  }
];

export const PLATFORM_DROP_ITEMS = [
  {
    label: "Android",
    value: 1
  },
  {
    label: "iOS",
    value: 2
  }
];

export const TOP_TEN_TEXT_STYLERS_ITEMS = [
  {
    inputLabel: "Change Bold Text",
    dropDownLabel: "Font Size",
    dropdownItems: [...boldFontSizeOptions]
  },
  {
    inputLabel: "Change Regular Text",
    dropDownLabel: "Font Size",
    dropdownItems: [...regularFontSizeOptions]
  },
  {
    inputLabel: "Change Small Text",
    dropDownLabel: "Font Size",
    dropdownItems: [...smallFontSizeOptions]
  }
];

export const SPIN_DESIGN_TEXT_STYLERS_ITEMS = [
  {
    inputLabel: "Change Bold Text",
    maxLength: 20,
    dropDownLabel: "Font Size",
    dropdownItems: [...boldFontSizeOptions]
  },
  {
    inputLabel: "Change Regular Text",
    maxLength: 40,
    dropDownLabel: "Font Size",
    dropdownItems: [...regularFontSizeOptions]
  }
];

export const SPIN_EMAIL_FIELD_LAYOUTS = [
  {
    inputLabel: "Change Email Text",
    dropDownLabel: "Font Size",
    dropdownItems: [...regularFontSizeOptions]
  },
  {
    inputLabel: "Change Button Text",
    dropDownLabel: "Font Size",
    dropdownItems: [...regularFontSizeOptions]
  }
];

export const CATEGORIES_LIST_LAYOUT = [
  TOP_IDEAL_TEXT_STYLERS_ITEMS,
  TOP_TRUSTLY_TEXT_STYLERS_ITEMS,
  TOP_TEN_TEXT_STYLERS_ITEMS
];

export const SPIN_DESIGN_LAYOUT = [SPIN_DESIGN_TEXT_STYLERS_ITEMS];

export const MENU_ROW_DATA_ITEMS = {
  boldText: {
    text: "",
    size: "12pt",
    color: "#000"
  },
  regularText: {
    text: "",
    size: "12pt",
    color: "#000"
  },
  smallText: {
    text: "",
    size: "12pt",
    color: "#000"
  },
  icon: {
    left: "",
    right: "",
    bigIcon: "",
    bigIconPosition: 0
  },
  colorCode: "#fff"
};

export const SPIN_DESIGN_DATA_ITEMS = {
  boldText: {
    text: "Spin",
    size: 0,
    color: "#000000"
  },
  regularText: {
    text: "Spin",
    size: 0,
    color: "#000000"
  },
  emailButton: {
    text: "Spin",
    size: 10,
    textColor: "#000000",
    buttonColor: "#000000"
  },
  emailText: {
    text: "string",
    color: "#000000",
    size: 10
  },
  colorCode: "#000000",
  spinDesignType: 1
};

export const SNACKBAR_COLOR = {
  bgGreen: "green",
  bgRed: "red"
};
