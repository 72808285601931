import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTES, MENU_ITEMS } from "../../shared/constants";
import Dashboard from "../../views/dashboard";
import { Loader } from "../../components/atoms/loader";
import ScreenHOC from "../../components/hoc";
import { signOut } from "../../redux/actions";
import Facility from "../../views/facility";
import Customer from '../../views/customers'
import Zones from '../../views/zones';

const AppRouter = ({ loader, userData, signOut = () => { } }) => {
  const history = useHistory();
  return (
    <ScreenHOC
      userData={userData}
      signOut={signOut}
      menuItems={MENU_ITEMS}
      onClickAction={(changedUrl) => {
        // if (changedUrl === ROUTES.LOGOUT) {
        //     setAuthorization(null);
        //     return <Redirect to={ROUTES.LOGIN} />
        // }
        // else {
        history.push(changedUrl);
        //  }
      }}
      location={history?.location?.pathname}
    >
      {loader && <Loader />}
      <main className="">
        <Switch>
          <Route exact path={ROUTES.DASHBOARD} component={Customer} />
          <Route exact path={ROUTES.FACILITY} component={Facility} />
          <Route exact path={ROUTES.SENSOR} component={Dashboard} />
          <Route exact path={ROUTES.ZONES} component={Zones} />
        </Switch>
      </main>
    </ScreenHOC>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.CommonReducer.userToken,
    loader: state.LoaderReducer.loader,
    snackbarVisibility: state.SnackbarReducer.snackbar,
    userData: state.CommonReducer.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
