import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';
import api from './Api';
import {store} from '../redux/store'
const initialValue = null;

export const ContextApp = createContext({ initialValue });

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(initialValue);
  const [facility, setFacility] = useState({});
  console.log("in context")
  const onLogin = async (userId) => {
    // console.log("userId",userId);
   const session = await Auth.currentSession();
    const groups = session.accessToken.payload['cognito:groups'];
    if (groups.includes('IDENTITY')) {
      const user = await api.users.getUserById(userId);
      const zones = await api.zones.getZonesByUser(userId);
      const imageProfile = await api.users.getUserImage(userId);
      const facilityOwner = await api.facilityOwner.getFacilityOwner(user[0].facilityOwnerId);
      let allFacilitiesForMyFacilityowner = [];
      if (facilityOwner[0].facilityIds) {
        allFacilitiesForMyFacilityowner = await api.facilities.getFacilitiesByIDs(
          facilityOwner[0].facilityIds.join(','),
        );
      }

      let facilities = [];
      if (zones && zones.length > 0) {
        // Gets facility ids for the facilites that the user has access to
        const arrayFacilities = [...new Set(zones.map((zones) => zones.facilityId))];
        facilities = await api.facilities.getFacilitiesByIDs(arrayFacilities.join(',')); // Facilities that have zones that I have access to
      }

      let allDataUser = {
        facilities: facilities ? facilities : [], // Facilities that have zones that I have access to
        allFacilitiesForMyFacilityowner,
        user: Array.isArray(user) && user.length > 0 ? user[0] : null,
        zones: zones ? zones : [],
        groups,
        imageProfile: imageProfile && imageProfile.length > 0 ? imageProfile[0].data : [],
        facilityOwner: facilityOwner[0],
        id: userId
      }; 
      // store.dispatch({type:USER_DATA,allDataUser})
      // setUserData(allDataUser)
      setUser(allDataUser);
    }
  };
  // console.log("user", user)

  const updateFacilityOwner = async () => {
    try {
      const facilityOwner = await api.facilityOwner.getFacilityOwner(user.facilityOwner.id);
      const allFacilitiesForMyFacilityowner = await api.facilities.getFacilitiesByIDs(
        facilityOwner[0].facilityIds.join(','),
      );
      setUser({
        ...user,
        facilityOwner: facilityOwner[0],
        allFacilitiesForMyFacilityowner,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const clearSession = async () => {
    await Auth.signOut();
  };

  const getFacilitySetterFunction = (pickedFacility) => {
    setFacility({ ...pickedFacility });
  };

  const userDataValue = useMemo(
    () => ({ onLogin, user, clearSession, updateFacilityOwner, facility, getFacilitySetterFunction }),
    [user, facility],
  );
  return <ContextApp.Provider value={userDataValue}>{children}</ContextApp.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.any,
};
