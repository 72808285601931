import { REHYDRATE } from "redux-persist";
import { SET_AUTHORIZATION, UPDATE_PLATFORM,USER_DATA } from "../actions";
// import { PLATFORM_DROP_ITEMS } from "../../shared/constants";
// const { updateAuthToken } = require(`../../helpers`);

const initialCommonState = {
  userToken: "",
  userData: {}
};

const CommonReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case SET_AUTHORIZATION:
      return {
        ...state,
        userToken: action.userToken 
        };
    case USER_DATA:
      // console.log("action.payload",action.payload);
      return {
        ...state,
        userData: action.payload
      };
    case UPDATE_PLATFORM:
      return {
        ...state,
        platform: action.platform
      };
    case REHYDRATE:
      let common =
        ((action || {}).payload || {}).CommonReducer || initialCommonState;
      // updateAuthToken(common.userToken || "");
      return {
        ...state,
        userToken: common.userToken,
        userData: common.userData,
        ...(action.payload || {}).common
      };
    default:
      return state;
  }
};

export default CommonReducer;
