// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

//prd
const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:f7365d6e-1c11-470c-a722-86e9ce8aa0ea',
  aws_user_pools_id: 'us-east-1_o777C1JGP',
  aws_user_pools_web_client_id: '3l1622jc6etdduba7m6vv99s30',
  oauth: {},
};

export default awsmobile;
