import React, { useState, useEffect } from "react";
import "./styles.scss";
let wrapperRef;
const HeaderProfile = ({ signOut, userData }) => {
  const [detailToggler, setDetailsToggler] = useState(false);
  const onToggle = () => {
    setDetailsToggler(!detailToggler);
  };
  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setDetailsToggler(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return (() => document.removeEventListener("mousedown", handleClickOutside))
  }, []);

  const setWrapperRef = node => {
    wrapperRef = node;
  };

  return (
    <li
      ref={setWrapperRef}
      class={`dropdown user user-menu ${detailToggler && "open"}`}
      onClick={onToggle}
    >
      <a class="dropdown-toggle" data-toggle="dropdown">
        <img
          src={require('../../../assets/img/user.png').default}
          class="user-image"
          alt="User Image"
        />
        <span class="hidden-xs">{userData?.name}</span>
      </a>
      <ul class="dropdown-menu">
        <li class="user-header">
          <img
            src={require('../../../assets/img/user.png').default}
            class="img-circle"
            alt="User Image"
          />
          <p>
            {userData?.name} - Web Developer
            <small>Member since Nov. 2012</small>
          </p>
        </li>
        <li class="user-body">
          <div class="row">
            <div class="col-xs-4 text-center">
              <a>Followers</a>
            </div>
            <div class="col-xs-4 text-center">
              <a>Sales</a>
            </div>
            <div class="col-xs-4 text-center">
              <a>Friends</a>
            </div>
          </div>
        </li>
        <li class="user-footer">
          <div class="pull-left">
            <a class="btn btn-default btn-flat">Profile</a>
          </div>
          <div class="pull-right">
            <a
              class="btn btn-default btn-flat"
              onClick={() => {
                signOut();
              }}
            >
              Sign out
            </a>
          </div>
        </li>
      </ul>
    </li>
  );
};

export default HeaderProfile;
