import React, { useState, useEffect, useContext } from "react";
import "./styles.scss";
import { ContextApp } from '../../utils/ContextApp'
const {
  KEY_CODES,
  LABELS,
  ELEMENT_ID,
  EMAIL_REGX,
  ROUTES
} = require(`../../shared/constants`);

const Login = props => {
  const { onLogin } = useContext(ContextApp);
  const { classes, checkLogin, history, userToken, setAuthorization } = props;
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [isEmailInvalid, setEmailInvalid] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (userToken) {
      history.push(ROUTES.DASHBOARD);
    }
  }, [userToken]);

  return (
    <div class="login-wrapper">
      <div class="image-section ">
        <div class="image-content display-flex-box">
          <div class="logo">
            <a>
              <img
                class="layout-logo"
                src={require("../../assets/img/logo.png").default}
              />
            </a>
          </div>
          <div class="wle">
            <p>Welcome</p>
          </div>
          <div>
            <div class="image-section-footer">
              <a>Forgot password?</a>
            </div>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="wle">
          <p>Welcome</p>
        </div>
        <div class="form-inner-container display-flex-box">
          <div class="form-auth">
            <form
              id="LoginForm"
              class="validate"
              onSubmit={e => e.preventDefault()}
            >
              <div class="input-label input-label-icon input-error-usesrName">
                <span class="input-usesrName"></span>
                <input
                  type="text"
                  class="input"
                  placeholder="Email Address"
                  name="usesrName"
                  id="usesrName"
                  required
                  value={email}
                  onChange={e => {
                    let value = e.target.value.toLowerCase();
                    setEmail(value);
                    let regexTestValue = EMAIL_REGX.test(value);
                    regexTestValue
                      ? setEmailInvalid("")
                      : setEmailInvalid("Email is not valid!");
                    if (value === "") {
                      setEmailInvalid(false);
                    }
                  }}
                />
                {isEmailInvalid && (
                  <div className="help-block">{isEmailInvalid}</div>
                )}
              </div>

              <div class="input-label input-label-icon input-error-password">
                <span class="icon-lock"></span>
                <input
                  type="password"
                  class="input"
                  placeholder="Password "
                  name="password"
                  id="password"
                  required
                  value={password}
                  onChange={e => {
                    e.target.value && setPasswordInvalid("");
                    setPassword(e.target.value);
                  }}
                />
                {passwordInvalid && (
                  <div className="help-block">{passwordInvalid}</div>
                )}
              </div>
              <div class="text-center display-flex-box">
                <input
                  type="submit"
                  class="submit-button"
                  value="Login to my account"
                  onClick={() => {
                    if (
                      !email ||
                      !password ||
                      isEmailInvalid ||
                      passwordInvalid
                    ) {
                      !email && setEmailInvalid("Email is required!");
                      !password && setPasswordInvalid("Password is required!");
                    } else {
                      checkLogin({ username: email, password }, setTimeout(() => {
                      }, 20000));
                    }
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
