import { SET_SENSOR_DATA } from "../actions";

const initialCommonState = {
  sensorData: false
};

const SensorReducer = (state = initialCommonState, action) => {
  switch (action.type) {
    case SET_SENSOR_DATA:
      return {
        ...state,
        sensorData: action.data
      };
    default:
      return state;
  }
};

export default SensorReducer;
