import { takeLatest, all, put } from "redux-saga/effects";

import {
  FETCH_CATEGORIES_DESIGN_LIST,
  SAVE_CATEGORY_CHANGE,
  UPLOAD_FILE,
  UPDATE_SPIN_DESIGN,
  setCategoriesDesignList,
  saveSpinDesigns
} from "../actions";
// import { getRequest, putRequest, postRequest } from "../../helpers";
// import { STATUS_CODE } from '../../shared/constants';
// import api from "../../shared/api";

const createFormData = fileData => {
  //   const data = new FormData();
  //   data.append("file", fileData.file);
  // return data;
};

function* fetchCategoriesDesignList({ appType = 1 }) {
  try {
    // const response = yield getRequest({
    //   API: `${api.URL.MENU_DESIGNS}?appType=${appType}&countryCode=NL`
    // });
    // if (response.status !== STATUS_CODE.successful) {
    // } else {
    //   yield put(setCategoriesDesignList(response.data.data.categorieDesigns));
    //   yield put(saveSpinDesigns(response.data.data.spinDesigns));
    // }
  } catch (error) {
    return;
  }
}

function* updateCategoryType({ data }) {
  try {
    // const response = yield putRequest({
    //   API: `${api.URL.CATEGORY_DESIGN}`,
    //   DATA: data
    // });
    // if (response.status !== STATUS_CODE.successful) {
    //   // yield put(saveResponse(response.data));
    // } else {
    //   // success(response);
    // }
  } catch (error) {
    return;
  } finally {
  }
}

function* updateSpinDesign({ data }) {
  try {
    // const response = yield putRequest({
    //   API: `${api.URL.SPIN_DESIGN}`,
    //   DATA: data
    // });
    // if (response.status !== STATUS_CODE.successful) {
    //   // yield put(saveResponse(response.data));
    // } else {
    //   // success(response);
    // }
  } catch (error) {
    return;
  } finally {
  }
}

function* uploadImageFile({ file, success }) {
  //   const formData = createFormData(file);
  try {
    // const response = yield postRequest({
    //   API: `${api.URL.UPLOAD_FILE}`,
    //   DATA: formData
    // });
    // if (response.status !== STATUS_CODE.successful) {
    // } else {
    //   success(response.data.fileUrl);
    // }
  } catch (error) {
    return;
  } finally {
  }
}

function* MenuSaga() {
  yield all([
    takeLatest(FETCH_CATEGORIES_DESIGN_LIST, fetchCategoriesDesignList),
    takeLatest(SAVE_CATEGORY_CHANGE, updateCategoryType),
    takeLatest(UPLOAD_FILE, uploadImageFile),
    takeLatest(UPDATE_SPIN_DESIGN, updateSpinDesign)
  ]);
}

export default MenuSaga;
