import React, { useContext } from "react";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import CustomTable from '../../components/atoms/custom-table';
import { ContextApp } from '../../utils/ContextApp';
import { FACILITY } from '../../shared/constants'
const Dashboard = ({ getSensorData, userData, stopLoader, startLoader }) => {
  const { user } = useContext(ContextApp);
  const fetchDataFacility = () => {
    startLoader()
    try {
      // const data = awa API.sensors.getSensorsById(userData.facilityOwner.sensorIds);
      // const { facilities } = userData
      const facilities = [{ "id": "c2565a79-da00-4008-a905-9f50a467a10c", "version": 2, "createdDate": "2021-01-05T16:37:50.534+00:00", "updatedDate": "2021-01-05T16:38:36.231+00:00", "updatedBy": "27cbc02b-1eb6-40a6-99c2-170e1dbe5191", "createdBy": "27cbc02b-1eb6-40a6-99c2-170e1dbe5191", "name": "Jalaj house", "description": "India team house", "address": "Chandigarh", "facilityOwnerId": "54700fc4-cd63-4aa4-9d01-08d8e2182e03", "zoneIds": ["3b025769-95b7-4ce9-901c-ae4bbb38eea5"] }, { "id": "1e7b1259-e379-4d55-a104-5f4a423aca6b", "version": 2, "createdDate": "2020-12-07T17:39:52.254+00:00", "updatedDate": "2020-12-07T17:40:58.483+00:00", "updatedBy": "27cbc02b-1eb6-40a6-99c2-170e1dbe5191", "createdBy": "27cbc02b-1eb6-40a6-99c2-170e1dbe5191", "name": "Test Desk", "description": "Desk at AW office", "address": "15543 Oxenham Avenue", "facilityOwnerId": "54700fc4-cd63-4aa4-9d01-08d8e2182e03", "zoneIds": ["695fd379-8bdb-473c-90b4-2d316c49365b"] }, { "id": "9248bd6e-4e42-4ac3-b870-982f6ef993a0", "version": 4, "createdDate": "2020-12-02T17:23:16.296+00:00", "updatedDate": "2021-01-15T21:44:58.635+00:00", "updatedBy": "27cbc02b-1eb6-40a6-99c2-170e1dbe5191", "createdBy": "27cbc02b-1eb6-40a6-99c2-170e1dbe5191", "name": "Active Witness Office", "description": "House and office", "address": "15543 Oxeham Avenue, White Rock", "facilityOwnerId": "54700fc4-cd63-4aa4-9d01-08d8e2182e03", "zoneIds": ["b044ae3e-1afa-4132-8eff-1a51cd91599e", "d2f2339e-837e-49c6-9258-30ec7c0a58d5"] }]
      const tableData = []
      // console.log("facilities", facilities);
      const tempData = facilities.filter((item, index) => {
        let { name = "-", address = "-", description = "-" } = item
        if (name === undefined || name === null) {
          name = '-'
        }
        else if (description === undefined || description === null) {
          description = '-'
        }
        else if (address === undefined || address === null) {
          address = '-'
        }
        tableData.push({ name, address, description })
      })
      setData([...tableData])
      stopLoader()
    } catch (error) {
      // snackbarVisible({
      //   msg: `${error?.msg}`,
      //   bgColor: SNACKBAR_COLOR.bgGreen
      // })
      console.log(error);
      stopLoader()
    }
  };

  useEffect(() => {
    fetchDataFacility();
  }, [JSON.stringify(userData)]);

  const [data, setData] = useState([])
  const [header, setHeader] = useState(["Sensor ID", "Sensor Name", "Sensor Facility", "Sensor Zone", "Action"])


  const sort = useCallback((column, order, data) => {
    const tempData = data
    tempData.sort((a, b) => {
      const getSelectedColumn = Object.keys(a)[column]
      if (!getSelectedColumn)
        return
      let value1 = a[getSelectedColumn]?.toLowerCase()
      let value2 = b[getSelectedColumn]?.toLowerCase()
      return order === 1 ? value1?.charCodeAt(0) - value2?.charCodeAt(0) : value2?.charCodeAt(0) - value1?.charCodeAt(0)
    })
    setData([...tempData])
  }, [])



  const searching = useCallback((searchedString = "2"?.toLowerCase(), data) => {
    const tempData = data.filter((item, index) => {
      const valueInItem = Object.values(item)
      return item
      if (valueInItem?.join(" ")?.toLowerCase()?.search(searchedString?.toString()?.trim()?.toLowerCase()) !== -1) {
      }
    })
    setData([...tempData])
  }, [])
  return (
    <div>
      <CustomTable data={data} header={FACILITY} sort={sort} />
    </div>
  );
}

export default Dashboard;
