import {
    SET_CATEGORIES_DESIGN_LIST,
    FETCH_CATEGORIES_DESIGN_LIST,
    SAVE_SPIN_DESIGNS
} from '../actions';

const initialCommonState = {
    categoriesDesignList: null,
    spinDesigns: null
};

const MenuReducer = (state = { ...initialCommonState }, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES_DESIGN_LIST:
            return {
                ...state,
            };
        case SET_CATEGORIES_DESIGN_LIST:
            return {
                ...state,
                categoriesDesignList: action.list
            };
        case SAVE_SPIN_DESIGNS:
            return {
                ...state,
                spinDesigns: action.design
            }
        default:
            return state;
    }
};

export default MenuReducer;